<template>
    <div class="root-index">
        <router-view v-if="flag" @playAudio="playAudioDo" @refresh="refreshDo"/>
    </div>
</template>
<script>
    import msg_audio from "a/audio/msg_audio.mp3";
    import start15_1 from "a/audio/exam/start15_1.mp3";
    import start15_2 from "a/audio/exam/start15_2.mp3";
    import start from "a/audio/exam/start.mp3";
    import end15_1 from "a/audio/exam/end15_1.mp3"; 
    import end15_2 from "a/audio/exam/end15_2.mp3";
    import end15_3 from "a/audio/exam/end15_3.mp3";
    import end_1 from "a/audio/exam/end_1.mp3";
    import end_2 from "a/audio/exam/end_2.mp3";
    import end_3 from "a/audio/exam/end_3.mp3";
    import isjj from "a/audio/isjj.mp3";
    // import submit from "a/audio/submit.mp3";
    import video_re from "a/audio/re.mp3";
    import video_stop from "a/audio/stop.mp3";
    import video_success from "a/audio/video_scu.mp3";

    export default {
        name: "index",
        data() {
            return {
                flag: true,
                audioObjArr: {},
                audioArr: {
                    "msg_audio": msg_audio,
                    "start15_1": start15_1,
                    "start15_2": start15_2,
                    "start": start,
                    "end15_1": end15_1,
                    "end15_2": end15_2,
                    "end15_3": end15_3,
                    "end_1": end_1,
                    "end_2": end_2,
                    "end_3": end_3,
                    "video_re": video_re,
                    "video_stop": video_stop,
                    "video_success": video_success,
                    "is_jj": isjj
                }
            };
        },
        created() {
            const zkz = localStorage.getItem('monitor-zkz');
            const subjectUuid = localStorage.getItem("monitor-subjectUuId");
            let timeUpload = window.localStorage.getItem('monitor-timeUpload-' + zkz + '-' + subjectUuid);
            if(timeUpload == 0){
                this.$store.state.timeUpload = 0;
            }
            if (timeUpload != 0 && timeUpload != null) {
                this.$store.state.timeUpload = Number(timeUpload);
                this.$store.state.timerUpload && window.clearInterval(this.$store.state.timerUpload);
                this.$store.state.timerUpload = window.setInterval(() => {
                    if (this.$store.state.timeUpload > 0) {
                        this.$store.state.timeUpload--;
                        window.localStorage.setItem('monitor-timeUpload-' + zkz + '-' + subjectUuid, this.$store.state.timeUpload);
                    } else {
                        window.clearInterval(this.$store.state.timerUpload);
                    }
                }, 1000)
            }
        },
        methods: {
            refreshDo() {
                this.flag = false;
                this.$nextTick(() => {
                    this.flag = true;
                })
            },
            playAudioDo(type) {
                if (!this.audioArr[type]) {
                    return false;
                }
                let audioBufferPlayer = new AudioBufferPlayer();
                let cacheArrayBuffer = null;
                this.onEventAudio(audioBufferPlayer, type);
                const xhr = new XMLHttpRequest(); //通过XHR下载音频文件
                xhr.open('GET', this.audioArr[type], true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function (e) { //下载完成
                    cacheArrayBuffer = this.response;
                    audioBufferPlayer.play(cacheArrayBuffer.slice(), 0).then(() => {

                    });
                };
                xhr.send();
            },
            onEventAudio(audioBufferPlayer, type) {
                audioBufferPlayer.on("autoPlayFail", () => {
                    //console.log("autoplay fail",type)
                })
                audioBufferPlayer.on("ended", () => {
                    //console.log("play end",type);
                })
                audioBufferPlayer.on("audioLevel", (level) => {
                    console.log('level:', level, type);
                })
            },
        }
    };
</script>
<style scoped lang="scss">
    .root-index {
        /*max-width: 500px;*/
        /*margin: 0 auto;*/
        height: 100%;
    }
</style>
